import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <noscript
          aria-hidden="true"
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJSTS9Z" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
          }}
        />
        <header className="main-header wrapper">
          <h1 className="logo aligncenter">
            <a href="/">Rovi - Supositorios de glicerina</a>
          </h1>
          <a href="#" className="btn-menu" />
        </header>
        <div className="main-menu">
          <div className="wrapper">
            <div className="row">
              <nav className="main-nav">
                <ul
                  className="inline-list alignleft nice-menu nice-menu-right nice-menu-main-menu"
                  id="nice-menu-1"
                >
                  <li className="menu-218 menu-path-front first odd ">
                    <a href="/" title>
                      Inicio
                    </a>
                  </li>
                  <li className="menu-308 menu-path-node-1  even ">
                    <a href="/por-que-rovi/">Nuestra marca</a>
                  </li>
                  <li className="menu-313 menuparent  menu-path-node-2  odd ">
                    <a href="/supositorios-de-glicerina/">
                      Supositorios
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-314 menu-path-node-3 first odd ">
                        <a href="/supositorios-de-glicerina/que-son/">
                          ¿Qué son los supositorios de glicerina?
                        </a>
                      </li>
                      <li className="menu-315 menu-path-node-4  even ">
                        <a href="/supositorios-de-glicerina/origen-vegetal/">
                          La Glicerina: origen vegetal
                        </a>
                      </li>
                      <li className="menu-316 menu-path-node-5  odd last">
                        <a href="/supositorios-de-glicerina/ventajas/">
                          Ventajas
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-317 menuparent  menu-path-node-6  even ">
                    <a href="/productos/">
                      Productos
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-319 menu-path-node-7 first odd ">
                        <a href="/productos/rovi-adultos/">Adultos</a>
                      </li>
                      <li className="menu-318 menu-path-node-8  even ">
                        <a href="/productos/rovi-ninos/">Niños</a>
                      </li>
                      {/* <li className="menu-320 menu-path-node-9  odd last">
                        <a href="/productos/">Lactantes</a>
                      </li> */}
                    </ul>
                  </li>
                  <li className="menu-349 menuparent  menu-path-node-10  odd ">
                    <a href="/estrenimiento/">
                      Estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-350 menu-path-node-11 first odd ">
                        <a href="/estrenimiento/que-es/">¿Qué es?</a>
                      </li>
                      <li className="menu-351 menu-path-node-12  even ">
                        <a href="/estrenimiento/tipos/">
                          Tipos de estreñimiento
                        </a>
                      </li>
                      <li className="menu-352 menu-path-node-13  odd ">
                        <a href="/estrenimiento/causas/">
                          Causas del estreñimiento
                        </a>
                      </li>
                      <li className="menu-353 menu-path-node-14  even last">
                        <a href="/estrenimiento/mitos/">Mitos</a>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-354 menuparent  menu-path-node-15 active  even last">
                    <a href="/ninos/">
                      Los niños y el estreñimiento
                      <span className="icon icon-v-down" />
                    </a>
                    <ul>
                      <li className="menu-360 menuparent  menu-path-node-21 active first odd ">
                        <a
                          href="/ninos/estrenimiento-lactantes/"
                          className="active"
                        >
                          Lactantes
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-361 menu-path-node-22 first odd ">
                            <a href="/ninos/estrenimiento-lactantes/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-362 menu-path-node-23  even ">
                            <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-363 menu-path-node-24  odd ">
                            <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-364 menu-path-node-25  even last">
                            <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-355 menuparent  menu-path-node-16  even last">
                        <a href="/ninos/estrenimiento-infantil/">
                          Niños
                          <span className="icon icon-v-down" />
                        </a>
                        <ul>
                          <li className="menu-356 menu-path-node-17 first odd ">
                            <a href="/ninos/estrenimiento-infantil/causas/">
                              Causas
                            </a>
                          </li>
                          <li className="menu-357 menu-path-node-18  even ">
                            <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                              Test
                            </a>
                          </li>
                          <li className="menu-358 menu-path-node-19  odd ">
                            <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                              Recomendaciones
                            </a>
                          </li>
                          <li className="menu-359 menu-path-node-20  even last">
                            <a href="/ninos/estrenimiento-infantil/alimentacion/">
                              Alimentación
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="breadcrumbs">
          <div className="wrapper">
            <div className="row">
              <ul className="inline-list l-grid-9">
                <li>
                  <a href="/">Inicio</a>
                </li>
                <li>
                  <a href="/ninos/">Los niños y el estreñimiento</a>
                </li>
                <li>Lactantes</li>
              </ul>{" "}
            </div>
          </div>
        </div>
        <div className="main-content wrapper">
          <div className="row">
            <div className="l-grid-5">
              <h1>estreñimiento en lactantes</h1>
              <p>
                En los recién nacidos el número de deposiciones medio oscila entre de{" "}
                <strong>2-3 deposiciones/día</strong> y con la edad va
                disminuyendo la frecuencia hasta que,{" "}
                <strong>a los 4 años</strong>, suelen hacer{" "}
                <strong>1 deposición al día</strong>. A partir de esta edad,
                la frecuencia de las deposiciones se asemeja a la frecuencia en
                el adulto,{" "}
                <strong>de 3 deposiciones/día a 3 deposiciones/semana</strong>.
              </p>
              <p>
                El estreñimiento, suele aparecer alrededor del mes de edad en
                forma de episodios de gran esfuerzo y llanto que duran 10-20
                minutos y cesan con la expulsión de las heces líquidas o
                blandas. Se origina por descoordinación entre el aumento en la
                presión abdominal y la relajación de la musculatura
                esfinteriana. El esfínter anal comienza a tener fuerza y se
                mantiene habitualmente cerrado, y el bebé puede relajarlo a
                voluntad pero todavía no identifica cuándo debe hacerlo. Cuando
                se produce el reflejo gastrocólico (necesidad de defecar) el
                bebé nota las contracciones y contribuye apretando los músculos
                del abdomen pero no sabe que a la vez debe relajar el esfínter
                anal. Así surgen los episodios de molestias, quejas, esfuerzo y
                a veces llanto, en los que el bebé se pone rojo, encoge las
                piernas y aprieta como si quisiera defecar pero no sale nada
                (mantiene contraído el esfínter anal). Tras 10-20 minutos de
                esfuerzos expulsa heces blandas (tras conseguir relajar el ano)
                y el bebé queda tranquilo.
              </p>
              <p>
                Su evolución natural es hacia la resolución espontánea, cuando
                el niño aprende a aumentar la presión abdominal mientras relaja
                el complejo esfinteriano.
              </p>
              <h2 className="vs-3">
                síntomas de estreñimiento en la lactancia:
              </h2>
              <p className="vs-3">
                <img
                  src="/sites/all/themes/custom/rovi/images/html/diagrama-lactante.png"
                  alt="Síntomas estreñimiento en la infancia"
                />
              </p>
              <p className="vs-3">
                <strong>Fuente:</strong> Jorge MM, Vicente RC. Estreñimiento y Encopresis. 
                Pediatr Integral. 2015; XIX (2): 127-138.
              </p>
            </div>
            <aside className="l-grid-3 l-prefix-1">
              <div>
                <img
                  src="/sites/all/themes/custom/rovi/images/html/aside-lactantes.jpg"
                  alt="El estreñimiento en lactantes"
                  className="round"
                />
              </div>
              <blockquote className="vs-3">
                <p>
                  El estreñimiento, suele aparacer alrededor del mes de edad en
                  forma de episodios de gran esfuerzo y llanto.
                </p>
              </blockquote>
            </aside>
          </div>
        </div>
        <div className="legales">
          <nav className="wrapper">
            <ul className="inline-list aligncenter">
              <li>
                <a href="/aviso-legal/">Aviso legal</a>
              </li>
              <li>
                <a href="/politica-de-privacidad/">Política de privacidad</a>
              </li>
              <li>
                <a href="/politica-cookies/">Política de cookies</a>
              </li>
              <li>
                <a href="/mapa-del-sitio/">Mapa del sitio</a>
              </li>
              <li>
                <a href="/contacto/">Contacto</a>
              </li>
              <li>
                <a href="/preguntas-frecuentes-estrenimiento/">FAQs</a>
              </li>
              <li>
                <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">
                  Configuración de cookies
                </a>
              </li>
            </ul>{" "}
          </nav>
        </div>
        <footer className="wrapper">
          <nav className="footer-seo row">
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/por-que-rovi/">Nuestra marca</a>
                </li>
                <li className="vs-3">
                  <a href="/supositorios-de-glicerina/">Supositorios</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/supositorios-de-glicerina/que-son/">
                        ¿Qué son los supositorios de glicerina?
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/origen-vegetal/">
                        La glicerina: origen vegetal
                      </a>
                    </li>
                    <li>
                      <a href="/supositorios-de-glicerina/ventajas/">
                        Ventajas
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/productos/">Productos</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/productos/rovi-adultos/">Adultos</a>
                    </li>
                    <li>
                      <a href="/productos/rovi-ninos/">Niños</a>
                    </li>
                    {/* <li>
                      <a href="/productos/">Lactantes</a>
                    </li> */}
                  </ul>
                </li>
                <li className="vs-3">
                  <a href="/estrenimiento/">estreñimiento</a>
                  <ul className="nivel-2">
                    <li>
                      <a href="/estrenimiento/que-es/">¿Qué es?</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/tipos/">Tipos</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/causas/">Causas</a>
                    </li>
                    <li>
                      <a href="/estrenimiento/mitos/">Mitos</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="l-grid-2 m-one-third center">
              <ul className="nivel-1">
                <li>
                  <a href="/ninos/">Los niños y el estreñimiento</a>
                  <ul className="nivel-1">
                    <li>
                      <a href="/ninos/estrenimiento-infantil/">Niños</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-infantil/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-infantil/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/ninos/estrenimiento-lactantes/">Lactantes</a>
                      <ul className="nivel-2">
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/causas/">
                            Causas
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/test-escala-bristol/">
                            Test
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/recomendaciones/">
                            Recomendaciones
                          </a>
                        </li>
                        <li>
                          <a href="/ninos/estrenimiento-lactantes/alimentacion/">
                            Alimentación
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="copy l-grid-3 m-full-width">
              <img
                src="/sites/all/themes/custom/rovi/images/html/logo-footer.png"
                alt="logo Rovi"
              />
              <p className="vs-2">
                <strong>©JNTL Consumer Health (Spain), S.L.</strong>
              </p>
              <p className="vs-2">
                Este sitio web está publicado
                <br className="m-hidden" />
                por JNTL Consumer Health (Spain), S.L.
                <br className="m-hidden" />
                único responsable de su contenido.
                <br />
                Está dirigida a visitantes de España.
              </p>
              <p className="semi-bold vs-2">Última actualización: marzo 2024</p>
            </div>
          </nav>
        </footer>
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/jquery-ui.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/vendor/picturefill.min.js" />
        <script src="/sites/all/themes/custom/rovi/js/main.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nvar scripts_loaded = false;\nfunction setCookie(name , state, days) {\ndate = new Date();\ndate.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));\nexpiresDate = date.toGMTString();\nwindow.document.cookie = name +'='+ state +'; expires=' + expiresDate + \"; path=/\";\n}\nfunction loadScripts() {\nscripts_loaded = true;\n}\nfunction jnjAcceptCookiesPolicy( e ) {\nsetCookie('jnj-cookies-policy', 'true', 1000);\nloadScripts();\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n}\n$(document).ready(function() {\n$(document).one( 'click', '#btn-accept-cookies-box', jnjAcceptCookiesPolicy );\n$(document).one('click', '#btn-close-cookies-box', function() {\nif ( $('#cookie-policy').length > 0 ) {\n$('#cookie-policy').remove();\n}\n});\n});\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
